import React from 'react';
import Header from './Header';
import Seo from './Seo';
import icon from './../../assets/icon.png';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Seo image={icon}/>
      <main className="text-gray-900">{children}</main>
    </>
  );
};

export default Layout;
